import scrollama from "scrollama";

const FeaturesScroll = {
    figure: null,
    featuresScroller: scrollama(),

    handleResize() {
        if (window.innerWidth > 1024) {
            this.featuresScroller.resize();
        }
    },

    init() {
        window.addEventListener("resize", this.handleResize.bind(this));
        if (window.innerWidth <= 1024) {
            return; // Exit if on mobile
        }
        setTimeout(() => {
            const scrolly = document.querySelector(".features-scroll");

            const article = scrolly.querySelector(".steps");
            const steps = article.querySelectorAll(".step");

            const texts = scrolly.querySelectorAll('.texts .text');

            this.figure = scrolly.querySelector("article");
            let offset = window.innerWidth > 1024 ? 0.7 : 0.9;
            this.featuresScroller.setup({
                step: ".step",
                offset: offset,
                progress: true,
                debug: false
            }).onStepEnter((response) => {
                FeaturesScroll.handleStepEnter(response, steps);
            })
                .onStepProgress((response) => {
                    FeaturesScroll.handleStepProgress(response, texts);
                });



            setTimeout(() => {
                this.handleResize();
            }, 300);
        }, 100);

    },
    handleStepEnter(response, steps) {
        // add active class for response.index step
        const {index} = response;
        steps.forEach((step, i) => {
            if (i === index) {
                step.classList.add("active");
            } else {
                step.classList.remove("active");
            }
        });

        // remove all active classes
        this.figure.querySelectorAll('.texts .text').forEach(item => item.classList.remove('active'));
        this.figure.querySelector('.texts .text:nth-of-type(' + (index+1) + ')').classList.add('active');
        //figure.querySelector('p').innerText = steps[index].querySelector('h3').innerText;

        const figureInner = document.querySelector('figure img');
        if (index === 0) {
            //figureInner.style.transform = `translateY(0)`;
            figureInner.style.top = `0%`;
        } else if (index === 1) {
            //figureInner.style.transform = `translateY(-33.13%)`;
            figureInner.style.top = `-100%`;
        } else {
            //figureInner.style.transform = `translateY(calc(-66.67% + 6px))`;
            figureInner.style.top = `-200%`;
        }
    },
    handleStepProgress(response, texts) {
        const {progress, index} = response;

        texts[index].style.setProperty('--text-after-height', progress*100 + '%');
        /*const text = step.querySelector('p');
        text.style.opacity = progress;
        console.log(progress);*/

        //step.querySelector('.progress').style.height = `${progress * 100}%`;

    }
    // window addeventlistener resize


};

export default FeaturesScroll;