import './sass/styles.scss';

import './js/aos.js';
import './js/main';

import FeaturesScroll from "./js/features-scroll";

document.addEventListener('DOMContentLoaded', () => {
    FeaturesScroll.init();
})

//import VideoScroll from "./js/video-scroll";
//VideoScroll.init();
